import React, { useEffect, useRef, useState, useReducer } from "react";
import { ObjectService } from "@getvia/sdk";
import Selectbox from "./selectbox";
import { TextInput } from "@mantine/core";
import { useNotifications } from '@mantine/notifications'
import { PostMoveAuto } from "../../../api/api";
// const objectId = "dtmi:com:via:Robot:VacuumRobot;1"; //TODO: make it configurable
const objectId = "dtmi:com:via:Robot:VacRobot;1"; //TODO: make it configurable
const viaConfig = {
  objectServiceOptions: {
    apiToken: "test",
    baseUrl: process.env.REACT_APP_VIA_OBJECT_API,
    wsUrl: process.env.REACT_APP_VIA_OBJECT_NOTIFICATION_HUB,
  },
};
const objectService = new ObjectService(viaConfig.objectServiceOptions);
// const objectSubscriptionService = new ObjectSubscriptionService(
//   viaConfig.objectServiceOptions
// );

const readOnlyInputStyle = {
  boxShadow: '-1px -1px 5px #fff, inset 2px 2px 5px #6e6e6e80',
  backgroundColor: '#9cc8e0',
}

const slotStyle = {
  fontSize: '10px',
  padding: '0px 6px',
}

const HomeCommand = "Home";
const PickCommand = "Pick";
const PlaceCommand = "Place";
const MoveCommand = "Move";
const GotoCommand = "Goto";
const ReseErrorCommand = "RESETERROR";
const ConnectCommand = "ConnectCmd";

const robotDefaultState = {
  VacuumRobot: {
    ConnectionStatus: "Disconnected",
  },
};

export default function RobotControls(props) {
  const { DTDLState, e95State, open, setOpen, station, setStation, arm, setArm, slot, setSlot, isFocused, setIsFocused, isDestFocused, setIsDestFocused, vtmArms, vtmStaions, DTDLModules, DTDLArmState, materialPresenceState } = props;
  const notifications = useNotifications()
  const [popUp, setPopUp] = useState("hidden");
  const [destinationSlot, setDestinationSlot] = useState(null)
  const [destinationStation, setDestinationStation] = useState(null)
  const [sourceSlot, setSourceSlot] = useState(null)
  const [sourceStation, setSourceStation] = useState(null)
  const [sourceModuleId, setSourceModuleId] = useState('dtmi:com:via:Module:LoadLockA;1')
  const [destinationModuleId, setDestinationModuleId] = useState('dtmi:com:via:Module:LoadLockA;1')
  const [manualAutoBtn, setManualAutoBtn] = useState('manual')  
  const [selectedStationA, setSelectedStationA] = useState(DTDLModules[0])
  const [selectedStationB, setSelectedStationB] = useState(DTDLModules[0])
  const [selectedArm, setSelectedArm] = useState('')

  const stationref = useRef(null);
  const Armref = useRef(null);

  const callNotification = (texts, color) => {
    notifications.showNotification({
      title: texts,
      message: '',
      autoClose: 2000,
      color: color,
      loading: false,
      disallowClose: false,
    })
  }

useEffect(() => {
  console.log('DTDLState-->', DTDLState)
}, [DTDLState])

  useEffect(() => {
    console.log('DTDLModules-->', DTDLModules)
    setSelectedStationA(DTDLModules[0])
    setSelectedStationB(DTDLModules[0])
  }, [DTDLModules])

  useEffect(() => {
    console.log('DTDLArmState-->', DTDLArmState)
    // setSelectedArm(DTDLArmState[0])
  }, [DTDLArmState])
  

  const SlotComponent = (props) => {
    return (
      <>
        <label for="slotInput" className="py-2 border-t text-lg">{popUp === MoveCommand ? 'Source Slot' : 'Slot'}</label>
        <div>
          <input 
            id="slotInput"
            type="text" 
            className="custom-input-field"
            defaultValue={slot === null ?  props.defaultVal : slot}
            onChange={(event) => {
              setSlot(event.target.value)
              setSourceSlot(event.target.value)
            }}
         />
          
        </div>
      </>
    );
  };


  const DestiSlotComponent = (props) => {
    return (
      <>
        <label for="destinationSlot" className="py-2 border-t text-lg">Destination Slot</label>
        <div>
          <input 
            type="text" 
            id="destinationSlot"
            name="destinationSlot"
            defaultValue={destinationSlot === null ?  props.defaultVal : destinationSlot}
            className="custom-input-field"
            onChange={(event) => setDestinationSlot(event.target.value)}
          />
        </div>
      </>
    );
  };


  const executeCommand = async (objectId, commandName, params) => {
    const request = {
      instanceId: objectId,
      commandName: commandName,
      parameters: params,
    };
    try {
      const response = await objectService.ExecuteCommand(request);
      if (response.success) {
        callNotification('Write Successfull', 'lime')
      }
    } catch (error) {
      callNotification('Failed to write', 'red')
    }
    
  };

  const executeCurSlotCommand = async (slot) => {
    let status = false;
    const request = {
      instanceId: sourceModuleId,
      commandName: 'SetCurSlot',
      parameters: {value: slot},
    };
    try {
      const response = await objectService.ExecuteCommand(request);
      if (response.success) {
        status = true
        callNotification('Write Successfully', 'lime')
      }
    } catch (error) {
      status = true
      callNotification('Failed to write', 'red')
    }
    return status
  };
  const executeHandler = async () => {
    if (popUp === PlaceCommand || popUp === PickCommand) {
      const response = await executeCurSlotCommand(slot ?? 1, '')
      if(response) {
        executeCommand(objectId, popUp, {
          Arm: selectedArm.ArmIdentifier,
          // Slot: slot ?? 1,
          Station: selectedStationA?.VTMStnNo,
        });
      }
      
    }

    if (popUp === GotoCommand) {
      executeCommand(objectId, GotoCommand, { Station: selectedStationA?.VTMStnNo });
    }

    if (popUp === MoveCommand) {
      const response = await executeCurSlotCommand(slot ?? 1, '')
      if(response) {
        executeCommand(objectId, "TRANSFER", {
          Arm: selectedArm?.ArmIdentifier,
          // DestinationSlot: destinationSlot,
          DestinationStation: selectedStationB?.VTMStnNo,
          // SourceSlot: sourceSlot,
          SourceStation: selectedStationA?.VTMStnNo,
        });
      }
    }

    // setArm(() => null);
    setSlot(() => null);
    // setStation(() => null);
    setPopUp(() => "hidden");
  };

  const resetMoveData = () => {
    setSourceModuleId('dtmi:com:via:Module:LoadLockA;1')
    setArm(1)
    setSourceStation(1)
    setDestinationStation(1)
    setSourceSlot(1)
    setDestinationSlot(1)
  }

  useEffect(() => {
    if(open=="Station") {
      function handleClickOutside(event) {
        // Check if the click is outside this specific Selectbox
        if (stationref.current && !stationref.current.contains(event.target)) {
          setOpen(''); // Hide the dropdown
        }
      }
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
   
  }, [stationref]);
  useEffect(() => {
    if(open=="Arm") {
      function handleClickOutside(event) {
        // Check if the click is outside this specific Selectbox
        if (Armref.current && !Armref.current.contains(event.target)) {
          setOpen(''); // Hide the dropdown
        }
      }
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
   
  }, [Armref]);

  const handleOutsideClick = (e) => {
    const clickedElement = e.target;
    if(clickedElement.id !== "arm_select" && clickedElement.id !== "station_select" && clickedElement.id !== "desti_station_select") {
      setOpen("")
    }
  }

  const handleMoveClick = async (e) => {
    if(manualAutoBtn === 'auto') {
      try{
        const response = await PostMoveAuto({
          Arm: 1,
          FromStation: 'dtmi:com:via:Module:LoadLockA;1',
          ToStation: 'dtmi:com:via:Module:LoadLockA;1',
          FromSlot: 1,
          ToSlot: 1
        })
  
        if(response.success) {
          callNotification('Write Successfull', 'lime')
        }
      }
      catch (error) {
        callNotification('Failed to write', 'red')
      }
      
    } else {
      resetMoveData();
      setPopUp(MoveCommand);
      setStation(1);
      setArm(1);
      setSlot(null)
    }
  }


  return (
    <div className="flex gap-5">
      <div className="mt-7">
      {/* {JSON.stringify(selectedStationA)} */}
        <div className="flex gap-2">
        <div
            className="border border-gray-400 border-opacity-60 w-40 bg-customGray rounded p-2 relative"
            style={{ height: '110px' }}
          >
            <div className="absolute -top-4 bg-customGray  w-3/4 left-1/2 mb-2 transform -translate-x-1/2">
              <h1 className="font-bold text-xs font-sans border border-gray-400 text-gray-600 border-opacity-70 rounded-sm text-center px-1 py-2">
                <span
                  className={`rounded-2xl p-1`}
                >
                Wafer Presence
                </span>
              </h1>
            </div>
            <div className="space-y-3 py-3">
              {/* {JSON.stringify(materialPresenceState)} */}
              <div className="flex flex-wrap gap-1 mt-3">
                {DTDLModules.map((e) =>  (<h1
                  className={`slot-indicator bg-gray-500 text-center font-bold rounded-xl flex justify-center items-center mb-0.5 text-white ${
                    e.locations?.some(location => location.MaterialPresence === 1)
                        ? 'bg-green-500'
                        : 'bg-gray-500'
                  }`}
                  style={slotStyle}
                >
                  {/* {materialPresenceState['dtmi:com:via:Location:LoadLockASlot1;1']?.Name} */}
                  {e?.Name}
                </h1>))}
            </div>
          </div>
        </div>

          <div
            className="border border-gray-400 border-opacity-60 w-40 bg-customGray rounded p-2 relative"
            style={{ minHeight: '385px' }}
          >
            <div className="absolute -top-4 bg-customGray  w-3/4 left-1/2 mb-2 transform -translate-x-1/2">
              <h1 className="font-bold text-xs font-sans border border-gray-400 text-gray-600 border-opacity-70 rounded-sm text-center px-1 py-2">
                <span
                  className={`rounded-2xl p-1 px-3 ${
                    DTDLState['dtmi:com:via:Robot:VacRobot;1']?.ConnectionStatus === 'Connected'
                      ? 'bg-green-500 text-white'
                      : 'bg-gray-500 text-white'
                  }`}
                >
                  Vacuum Robot
                </span>
              </h1>
            </div>
            <div className="space-y-3 py-3">
              <div className="grid grid-cols-2 gap-1 mt-3">
                  {/* {JSON.stringify(DTDLState)} */}
                  {DTDLArmState.map((e) =>  (<h1
                  className={`slot-indicator bg-gray-500 text-center font-bold rounded-xl flex justify-center items-center mb-0.5 text-white ${
                    e.locations?.some(location => location.MaterialPresence === 1)
                        ? 'bg-green-500'
                        : 'bg-gray-500'
                  }`}
                  style={slotStyle}
                >
                  {/* {materialPresenceState['dtmi:com:via:Location:LoadLockASlot1;1']?.Name} */}
                  {e?.Name}
                </h1>))}

                
                {/* {DTDLArmState && DTDLArmState.length > 0 ? (<>
                <h1
                  className={`slot-indicator bg-gray-500 text-center font-bold rounded-xl flex justify-center items-center mb-0.5 text-white ${
                    DTDLArmState[0]?.MaterialPresence !== 0
                        ? 'bg-green-500 text-white'
                        : 'bg-gray-500 text-white'
                  }`}
                  style={slotStyle}
                >
                  {DTDLArmState[0]?.Name}
                </h1>
                <h1
                  className={`slot-indicator bg-gray-500 text-center font-bold rounded-xl flex justify-center items-center mb-0.5 text-white ${
                    DTDLArmState[1]?.MaterialPresence !== 0
                        ? 'bg-green-500 text-white'
                        : 'bg-gray-500 text-white'
                  }`}
                  style={slotStyle}
                >
                  {DTDLArmState[1]?.Name}
                </h1></>) : ''} */}
              </div>
              <div className="flex flex-col gap-1 mb-2 -mt-3">
                
                <div className="grid grid-cols-2 gap-2 text-xs font-semibold">
                  <div>Driver Error</div>
                  <div
                    className="bg-blue-200 px-2 dysing-input rounded-sm h-5 leading-relaxed"
                    style={readOnlyInputStyle}
                  >
                    {DTDLState['dtmi:com:via:Robot:VacRobot;1']?.Error ?? ' '}
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-2 text-xs font-semibold">
                  <div>Device Error</div>
                  <div
                    className="bg-blue-200 px-2 dysing-input rounded-sm h-5 leading-relaxed"
                    style={readOnlyInputStyle}
                  >
                    {DTDLState['dtmi:com:via:Robot:VacRobot;1']?.DeviceErrCode ?? ' '}
                  </div>
                </div>
     
              </div>

              <div
                className="text-center text-xs  space-y-3"
                style={{ marginTop: '27px' }}
              >
                <button
                  className="bg-btnBg font-medium w-full rounded text-gray-600 px-1 py-1 custom-shadow custom-shadow-hover uppercase text-base"
                  onClick={() => {
                    executeCommand(objectId, HomeCommand, {});
                  }}
                  disabled={e95State?.find(o => o.name === "VTMStatus").currentValue == 2 || DTDLState['dtmi:com:via:Robot:VacRobot;1']?.ConnectionStatus !== 'Connected'}
                >
                  Home
                </button>
                <button
                  className="bg-btnBg font-medium w-full rounded text-gray-600 px-1 py-1 custom-shadow custom-shadow-hover uppercase text-base"
                  onClick={() => {resetMoveData();setPopUp(GotoCommand);;setStation(1);setArm(1);setSlot(null)}}
                  disabled={e95State?.find(o => o.name === "VTMStatus").currentValue==2 || DTDLState['dtmi:com:via:Robot:VacRobot;1']?.ConnectionStatus !== 'Connected'}
                >
                  Goto
                </button>

                <button
                  className="bg-btnBg w-full  font-medium rounded text-gray-600 px-1 py-1 custom-shadow custom-shadow-hover uppercase text-base"
                  onClick={() => {resetMoveData();setPopUp(PickCommand);setStation(1);setArm(1);setSlot(null)}}
                  disabled={e95State?.find(o => o.name === "VTMStatus").currentValue == 2 || DTDLState['dtmi:com:via:Robot:VacRobot;1']?.ConnectionStatus !== 'Connected'}
                >
                  Pick
                </button>
                <button
                  className="bg-btnBg w-full  font-medium rounded text-gray-600 px-1 py-1 custom-shadow custom-shadow-hover uppercase text-base"
                  onClick={() => {resetMoveData();setPopUp(PlaceCommand);setStation(1);setArm(1);setSlot(null)}}
                  disabled={e95State?.find(o => o.name === "VTMStatus").currentValue==2 || DTDLState['dtmi:com:via:Robot:VacRobot;1']?.ConnectionStatus !== 'Connected'}
                >
                  Place
                </button>
                <button
                  className="bg-btnBg w-full flex gap-2 justify-center items-center font-medium rounded text-gray-600 px-1 py-1 custom-shadow custom-shadow-hover uppercase text-base"                  
                  onClick={handleMoveClick}
                  disabled={DTDLState['dtmi:com:via:Robot:VacRobot;1']?.ConnectionStatus !== 'Connected'}
                >
                  <span>Move</span> <span className="rounded-xl w-12 px-1 h-4 py-0" style={{fontSize: '9px', boxShadow: "0px 0px 0px 1px #9d9999", lineHeight: "1.8"}}>{manualAutoBtn.toLocaleUpperCase()}</span>
                </button>
                <button
                  className="bg-btnBg font-medium w-full rounded text-gray-600 px-1 py-1 custom-shadow custom-shadow-hover uppercase text-base"
                  disabled={DTDLState['dtmi:com:via:Robot:VacRobot;1']?.ConnectionStatus !== 'Connected'}
                  onClick={() => {
                    executeCommand(objectId, ReseErrorCommand, {});
                  }}
                >
                  Reset Error
                </button>
                
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* MODAL */}

      <div
        className={` fixed top-0 left-0 h-full w-full bg-black bg-opacity-15 z-50 justify-center align-middle ${popUp}`}
        onClick={handleOutsideClick}
      >
        <div
          className="absolute w-full h-full "
          onClick={() => setPopUp("hidden")}
        ></div>
        <div className="bg-white rounded-lg  w-2/6 mx-auto relative  top-36">
          <button
            className=" bg-red-500 text-white rounded-full h-6 w-6 text-lg leading-4 hover:bg-opacity-80 absolute z-30 right-2 top-2"
            style={{lineHeight: .2, paddingBottom: '3px'}}
            onClick={() => setPopUp("hidden")}
          >
            x
          </button>
          <div className="text-lg p-3 font-bold bg-gray-300">
            {popUp === 'Goto' ? popUp + ' Station' : popUp + ' Wafer' }
          </div>
          <div className="p-3 gap-4 grid grid-cols-2">
      
          {popUp === MoveCommand ? <>
          <div className="py-2 border-t text-lg">Manual/Auto</div>
          <div className="rounded-full flex p-2 text-center bg-flip-blue mt-2"> 
                <div 
                onClick={()=>{setManualAutoBtn('manual')}}   
                className={`flex-1 rounded-l-full p-1 bg-flip-label  cursor-pointer ${manualAutoBtn === 'manual' ? 'bg-flip-label-active' : ''} -mr-3  text-sm transition-all`}>Manual</div>
                <div className="relative rounded-full  flex-1 bg-flip-btn cursor-pointer hover:scale-110 transition-all active:scale-100" 
              >
              <div className={`rounded-full h-5 w-5 bg-cyan-400 border-2 shadow-inner shadow-gray-500  top-1 transition-all 
                ${manualAutoBtn == 'manual' ? "left-1 absolute" :
                  manualAutoBtn == 'auto' ? "right-1 absolute":'mx-auto mt-1 opacity-50 bg-gray-300'}`}
                ></div> 
            </div>
          <div 
            onClick={()=>{setManualAutoBtn('auto')}}   
          className={`flex-1 rounded-r-full p-1 bg-flip-label cursor-pointer ${manualAutoBtn === 'auto' ? 'bg-flip-label-active' : ''} -ml-3 text-sm transition-all`}>Auto</div>
        </div></> : ''}

            <div className={`col-span-2 grid grid-cols-2 gap-4 ${(popUp === PickCommand ||
              popUp === GotoCommand ||
              popUp === PlaceCommand || popUp === MoveCommand ) ?'':'hidden'} `}>
              <div className="py-2 border-t text-lg">{popUp === MoveCommand ? 'Source Station': 'Station'}</div>
              <div className="border-t"  ref={stationref}>
                <div className={`relative w-full `}>
                  {/* <div style={{width: 300, wordWrap: 'break-word'}}>{JSON.stringify(selectedStationA)}</div> */}
                    {/* <div  id="station_select" className=' p-2 bg-gray-200 w-full cursor-pointer' onClick={()=>{open=='Station'?setOpen(""): setOpen("Station")}}>
                    {vtmStaions?.map((i,j)=>(
                      i.value==station && i.name
                      ))}
                    </div> */}

                    <div  id="station_select" className=' p-2 bg-gray-200 w-full cursor-pointer' onClick={()=>{open=='Station'?setOpen(""): setOpen("Station")}}>
                    {DTDLModules?.map((i,j)=>(
                      i?.$dtId === selectedStationA?.$dtId && i.Name
                      ))}
                      {/* {setSelectedStationA.Name} */}
                    </div>
                    <div className={` absolute bg-white rounded shadow max-h-64 z-20 w-full border overflow-y-scroll border-gray-400 ${open != "Station"&& 'hidden'}`}>
                      {DTDLModules?.map((i,j)=>(
                        <div onClick={()=>{setSelectedStationA(i);setOpen('');setSourceStation(i.$dtId);setSourceModuleId(i.$dtId)}} className='p-2 text-md border-b w-full hover:bg-gray-200 cursor-pointer'>  {i.Name}</div>
                      ))}
                    </div>
                  
                    {/* <div className={` absolute bg-white rounded shadow max-h-64 z-20 w-full border overflow-y-scroll border-gray-400 ${open != "Station"&& 'hidden'}`}>
                      {vtmStaions?.map((i,j)=>(
                        i.name !== 'ISO' ? <div onClick={()=>{setStation(i.value);setOpen('');setSourceStation(i.value);setSourceModuleId(i.mId)}} className='p-2 text-md border-b w-full hover:bg-gray-200 cursor-pointer'>  {i.name}</div> : ''
                      ))}
                    </div> */}
                  </div>
              </div>
            </div>

            {(popUp === PickCommand || popUp === PlaceCommand || popUp === MoveCommand) && (
              <SlotComponent defaultVal={1}></SlotComponent>
            )}

            {popUp === MoveCommand ? (
              <>
              <div className="py-2 border-t text-lg">Destination Station</div>
                <div className="border-t"  ref={stationref}>
                <div className={`relative w-full `}>
                    <div  id="desti_station_select" className=' p-2 bg-gray-200 w-full cursor-pointer' onClick={()=>{open=='Station' ? setOpen(""): setOpen("Destination Station")}}>
                    {DTDLModules?.map((i,j)=>(
                      i.$dtId === selectedStationB.$dtId && i.Name
                      ))}
                    </div>
                  {/* {JSON.stringify(vtmStaions)} */}
                    <div className={` absolute bg-white rounded shadow max-h-64 z-20 w-full border overflow-y-scroll border-gray-400 ${open != "Destination Station" && 'hidden'}`}>
                      {DTDLModules?.map((i,j)=>(
                        i.Name !== 'ISO' ? <div onClick={()=>{setOpen('');setSelectedStationB(i);setDestinationStation(i.$dtId);setDestinationModuleId(i.$dtId)}} className='p-2 text-md border-b w-full hover:bg-gray-200 cursor-pointer'>  {i.Name}</div> : ''
                      ))}
                    </div>
                  </div>
              </div>
                {/* <div className="col-span-2 flex flex-col gap-0 w-full flex-1">
                  <div className="gap-4 grid grid-cols-2 mb-0">
                    <label className="text-lg pt-2 border-t w-full">
                      Source Station
                    </label>
                    <select
                      className="border-0 p-2 bg-gray-200 w-full cursor-pointer"
                      onChange={(e) => setSourceStation(e.target.value)}
                      value={sourceStation}
                    >
                      <option value="">select</option>
                      {stationsList.map((item) => <option value={item.value}>{item.label}</option>)}
                    </select>
              </div>
            </div> */}

            
        
                </>
              ) : ''}
            

        {(popUp === MoveCommand) && (
            
          <DestiSlotComponent defaultVal={2}></DestiSlotComponent>
  
        )}

        <div className={`col-span-2 grid grid-cols-2  gap-4 ${(popUp === PickCommand || popUp === PlaceCommand || popUp === MoveCommand) ?'':'hidden'} `}>
            <div className="py-2 border-t text-lg">Arm</div>
                <div className="border-t"  ref={Armref} >
                  <div className={`relative w-full `}>
                      <div id="arm_select" className='p-2 bg-gray-200 w-full cursor-pointer' onClick={()=>{open=='Arm'?setOpen(""): setOpen("Arm")}}>
                      {DTDLArmState?.map((i,j)=>(
                        i?.$dtId === selectedArm?.$dtId && i.Name
                        ))}
                        {selectedArm == '' && 'select'}
                      </div>
                    
                      <div className={` absolute bg-white rounded shadow max-h-64 z-20 w-full border overflow-y-scroll border-gray-400 ${open!="Arm"&& 'hidden'}`}>
                        {DTDLArmState?.map((i,j)=>(
                        i?.SubstrateType === selectedStationA?.SubstrateType && <div onClick={()=>{setSelectedArm(i);setOpen('')}} className='p-2 text-md border-b w-full hover:bg-gray-200 cursor-pointer transition-all'>  {i.Name}</div>
                        ))}
                      </div>
                    </div>
                </div>
          </div>

            <button
              className="w-28 bgPrime2 shadow-shadow100 font-bold text-xl px-2 py-1 rounded-lg transition-all hover:bg-white"
              onClick={executeHandler}
              disabled={popUp === MoveCommand && !(destinationSlot && destinationStation && sourceSlot && sourceStation)}
            >
              Move
            </button>
            
          </div>
        </div>
      </div>
    </div>
  );
}
