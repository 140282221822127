import React, { useEffect, useState } from "react";

import Images from "../../Images";


// const alarms_url = "http://localhost:8083/alarm";
const connectionStatusUrl = process.env.REACT_APP_VIA_OBJECT_API+"/api/object/dtmi%3Acom%3Avia%3AIOService%3AIOService%3B1/ConnectionStatus/propertyValue";
export default function IoService() {
  const [connectionStatus, setConnectionStatus] = useState(null);
  const [close, setclose] = useState(false);


  useEffect(() => {
    const checkConnectionStatus = async () => {
      try {
        const response = await fetch(connectionStatusUrl);
        const data = await response.json();
        console.log('io dataset',data)
        setConnectionStatus(data);  // Assuming response JSON contains { value: 0 }
      } catch (error) {
        console.error("Error fetching connection status:", error);
      }
    };

    checkConnectionStatus();
  }, []);

  return (
    connectionStatus === 0 && (
    close==false&&
    <div className="absolute w-full top-1 mt-2  z-50 bg-gray-300 bg-opacity-0  backdrop-blur-md border border-gray-400 rounded-xl shadow" >
      <div className="relative w-full p-5 font-bold  text-red-600  ">
          <button className="text-sm absolute right-2 top-2 text-red-500 cursor-pointer" onClick={()=>setclose(true)}>✖</button>
          <div className=" mx-auto text-center">
              <div className="text-2xl">I/O SERVICES DOWN</div> 
            </div>
      </div>
    </div>
  
)
  );
}

