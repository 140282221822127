import axios from 'axios'
import Cookies from 'js-cookie'

const token = Cookies.get('token')

const config = {
  headers: { Authorization: `Bearer ${token}` },
}

const ioBaseURl = process.env.REACT_APP_VIA_CORE_API
const jbBaseUrl = process.env.REACT_APP_VIA_RULE_ENGINE_API
const moveAutoBaseUrl = process.env.REACT_APP_VIA_MOVEAUTO_URL
const ObjectApiUrl = process.env.REACT_APP_VIA_OBJECT_API
const setPropertyUrl = `/api/object/setProperty`
const getJobSequenceUrl = '/api/JobSequence'

export const Api = axios.create({
  baseURL: jbBaseUrl + '/api',
})

export const ioApi = axios.create({
  baseURL: ioBaseURl,
})

export const moveAutoApi = axios.create({
  baseURL: moveAutoBaseUrl,
})

export const ObjectApi = axios.create({
  baseURL: ObjectApiUrl,
})

export const ReadAllChannelEnums = async () => {
  try {
    const res = await ioApi.get('/api/IOChannel/enums', config)
    return {
      status: 200,
      data: res || null,
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return { error: error.response.data.message }
    }
    return { status: 400, error }
  }
}

export const ReadAllChannels = async () => {
  try {
    const res = await ioApi.get('/api/IOChannel', config)
    return {
      status: 200,
      data: res || null,
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return { error: error.response.data.message }
    }
    return { status: 400, error }
  }
}


export const ReadAllChannelStates = async () => {
  try {
    const res = await ioApi.get(`api/IO/state/get/all`, config)
    return {
      status: 200,
      data: res || null,
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return { error: error.response.data.message }
    }
    return { status: 400, error }
  }
}
export const getAllChambers = async () => {
  const url =
  process.env.REACT_APP_VIA_RULE_ENGINE_API +"/api/Chamber";
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await res.json();
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }
    return json;
  } catch (error) {
    // throw new Error("Failed to fetch API");
    return error;
  }
}
export const getAllRecipesByChamber = async (chamberId) => {
  const url =
  process.env.REACT_APP_VIA_RULE_ENGINE_API +"/api/Recipes/ByChamber/"+chamberId;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await res.json();
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }
    return json;
  } catch (error) {
    // throw new Error("Failed to fetch API");
    return error;
  }
}

export const PostMoveAuto = async (data) => {
  try {
    const res = await moveAutoApi.post('/api/Job', data, config)
    return {
      status: 200,
      data: res || null,
    }
  } catch (error) {
    if (error.response) {
      return { error: error.response.data.message }
    }
    return { status: 400, error }
  }
}

export const SetProperty = async (data) => {
  try {
    const res = await ObjectApi.post(`${setPropertyUrl}`, data, config)
    return {
      status: res.status || 200,
      response: res.data || null,
    }
  } catch (error) {
    return { status: 400, error }
  }
}

export const GetAllJobSequence = async () => {
  try {
    const res = await Api.get(`/JobSequence`, config)
    return {
      status: 200,
      data: res?.data || null,
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return { error: error.response.data.message }
    }
    return { status: 400, error }
  }
}

// export const getAllChambers = async () => {
//   const url =
//   Api +"/api/Chamber";
//   try {
//     const res = await fetch(url, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     const json = await res.json();
//     if (json.errors) {
//       console.error(json.errors);
//       throw new Error("Failed to fetch API");
//     }
//     return json;
//   } catch (error) {
//     // throw new Error("Failed to fetch API");
//     return error;
//   }
// }