import React, { useState, useRef, useEffect } from 'react';
import Images from '../../../Images';
import Header from '../../Parts/headerComponents/header';
import Footer from '../../Parts/footerComponents/footer';
import TabNav from '../../Parts/tabNav/tabNav';
import E95sidemenu from './e95sidemenu';
import E95Screendemo from './e95Screendemo';
import E95ScreenMaintenence from './e95ScreenMaintenence';
import { useLocation } from 'react-router-dom';
import {Tooltip} from "react-tooltip"
import 'react-tooltip/dist/react-tooltip.css'
import { ChannelService, ChannelSubscriptionService } from "@getvia/sdk";
import { GetAllJobSequence } from '../../api/api';

let content = [
  { 
    name:'E95Screendemo',
    module:<E95ScreenMaintenence/>,
    type:'image',
    data:{className:'mx-auto p-1',
    e95options:[
      {
        name:'ATM1',channelName:'ATM1.WfrPresence',
        options:{
          placementoptions:[
            {name:'Remove Wafer from ATM',channelName:'ATM1.WfrPresence',value:false},
            {name:'Add Wafer to ATM',channelName:'ATM1.WfrPresence',value:true},
          ],
          waferoptions:[
            {name:'Move Wafer to LLA',channelName:'LLA.WfrPresence',value:true,waferfrom:'ATM1.WfrPresence'},
            {name:'Move Wafer to LLB',channelName:'LLB.WfrPresence',value:true,waferfrom:'ATM1.WfrPresence'},
            {name:'Move Wafer to LP1',channelName:'LP1.WfrPresence',value:true,waferfrom:'ATM1.WfrPresence'},
            {name:'Move Wafer to LP2',channelName:'LP2.WfrPresence',value:true,waferfrom:'ATM1.WfrPresence'},
          ],
          atmoptions:[
            {name:'Move ATM to LLA',channelName:'ATM1.Station',value:'LLA'},
            {name:'Move ATM to LLB',channelName:'ATM1.Station',value:'LLB'},
            {name:'Move ATM to LP1',channelName:'ATM1.Station',value:'LP1'},
            {name:'Move ATM to LP2',channelName:'ATM1.Station',value:'LP2'},
          ],type:'ATM'
        }
      },
      {
        name:'ATM2',channelName:'ATM2.WfrPresence',
        options:{
          placementoptions:[
            {name:'Remove Wafer from VTM',channelName:'ATM2.WfrPresence',value:false},
            {name:'Add Wafer to VTM',channelName:'ATM2.WfrPresence',value:true},
          ],
          waferoptions:[
            {name:'Move Wafer to LLA',channelName:'LLA.WfrPresence',value:true,waferfrom:'ATM2.WfrPresence'},
            {name:'Move Wafer to LLB',channelName:'LLB.WfrPresence',value:true,waferfrom:'ATM2.WfrPresence'},
            {name:'Move Wafer to DicingChamberA',channelName:'PM1.WfrPresence',value:true,waferfrom:'ATM2.WfrPresence'},
            // {name:'Move Wafer to Dicing',channelName:'PM2.WfrPresence',value:true,waferfrom:'ATM2.WfrPresence'},
          ],
          atmoptions:[
            {name:'Move VTM to LLA',channelName:'ATM2.Station',value:'LLA'},
            {name:'Move VTM to LLB',channelName:'ATM2.Station',value:'LLB'},
            {name:'Move VTM to DicingChamberA',channelName:'ATM2.Station',value:'PM1'},
            // {name:'Move VTM to Dicing',channelName:'ATM2.Station',value:'PM2'},
          ],type:'VTM'
        }
      },
      {
        name:'LLA',channelName:'LLA.WfrPresence',
        options:{
          placementoptions:[
            {name:'Remove Wafer from LLA',channelName:'LLA.WfrPresence',value:false},
            {name:'Add Wafer to LLA',channelName:'LLA.WfrPresence',value:true},
          ],
          waferoptions:[
            {name:'Move Wafer to ATM',channelName:'ATM1.WfrPresence',value:true,waferfrom:'LLA.WfrPresence'},
            {name:'Move Wafer to VTM',channelName:'ATM2.WfrPresence',value:true,waferfrom:'LLA.WfrPresence'},
          ]
        }
      },
      {
        name:'LLB',channelName:'LLB.WfrPresence',
        options:{
          placementoptions:[
            {name:'Remove Wafer from LLB',channelName:'LLB.WfrPresence',value:false},
            {name:'Add Wafer to LLB',channelName:'LLB.WfrPresence',value:true},
          ],
          waferoptions:[
            {name:'Move Wafer to ATM',channelName:'ATM1.WfrPresence',value:true,waferfrom:'LLB.WfrPresence'},
            {name:'Move Wafer to VTM',channelName:'ATM2.WfrPresence',value:true,waferfrom:'LLB.WfrPresence'},
          ]
        }
      },
      {
        name:'LP1',channelName:'LP1.WfrPresence',
        options:{
          placementoptions:[
            {name:'Remove Wafer from LP1',channelName:'LP1.WfrPresence',value:false},
            {name:'Load Wafer to LP1',channelName:'LP1.WfrPresence',value:true},
          ],
          waferoptions:[
            {name:'Move Wafer to ATM1',channelName:'ATM1.WfrPresence',value:true,waferfrom:'LP1.WfrPresence'}
          ]
        }
      },
      {
        name:'LP2',channelName:'LP2.WfrPresence',
        options:{
          placementoptions:[
            {name:'Remove Wafer from LP2',channelName:'LP2.WfrPresence',value:false},
            {name:'Load Wafer to LP2',channelName:'LP2.WfrPresence',value:true},
          ],
          waferoptions:[
            {name:'Move Wafer to ATM1',channelName:'ATM1.WfrPresence',value:true,waferfrom:'LP2.WfrPresence'}
          ]
        }
      },
      {
        name:'LLA',channelName:'LLA.DoorSts',
        options:{
          placementoptions:[
            {name:'Close Door of LLA',channelName:'LLA.DoorSts',value:false},
            {name:'Open Door of LLA',channelName:'LLA.DoorSts',value:true},
          ]
        }
      },
      {
        name:'LLB',channelName:'LLB.DoorSts',
        options:{
          placementoptions:[
            {name:'Close Door of LLB',channelName:'LLB.DoorSts',value:false},
            {name:'Open Door of LLB',channelName:'LLB.DoorSts',value:true},
          ]
        }
      },
    ]
  }
  }
]

const viaConfig = {
  channelServiceOptions: {
    apiToken: "test",
    baseUrl: process.env.REACT_APP_VIA_CORE_API,
    wsUrl: process.env.REACT_APP_VIA_NOTIFICATION_HUB
  },
};
const channelService = new ChannelService(viaConfig.channelServiceOptions);

export default function E95Maintenance(props) { 
    const channels = useRef();
    const location = useLocation();
    const [operation, setOperation] = useState('home');
    const [description, setDescription] = useState('');
    const [startJsPopup, setStartJsPopup] = useState(false);
    const [cycles, setCycles]= useState(1)
    const [jobID, setjobID]= useState(null)
    const [jobSequences, setJobSequences] = useState([]);
    const [selectedJobSequenceId, setSelectedJobSequenceId] = useState(null);

    const fetchChannelsStates = async () => {
      const response = await channelService.ChannelStates();
      channels.current = response;
    };

    const IOWrite = (channelName, value) => {
      if (channelName) {
        const channel = channels.current.find(obj => {
          return obj.channelName === channelName
        })
        if (channel) {
          channel.data.value = value;
          channelService.Write(channel).then(() => {
          });
        }
      }
    }

    const JobSequenceWriteToChannel = async (a) => {
        const writeChannelName = 'LP1.JobCmd';
        await IOWrite('JobId', jobID);
        // await IOWrite('ProcessRecipe', processRecipe);
        await IOWrite('NoOfCycles', cycles);
        await IOWrite(writeChannelName, a);
        await IOWrite('ProcessJobSequence', selectedJobSequenceId)
    }

    const getAllJobSequence = async () => {
      const jobs = await GetAllJobSequence()
      if(jobs && jobs.data?.length > 0){
        setJobSequences(jobs.data);
      }
    };

    useEffect(() => {
      getAllJobSequence()
      const fetChannelData = async () => {
        await fetchChannelsStates();
      };
      fetChannelData();
    }, []);

    const terminateSequence = async () => {
      let e = localStorage.getItem('currenttcSequence');
      
      if(e) {
        await fetch(
          process.env.REACT_APP_VIA_RULE_ORCHES_API +
            "/api/Sequence/terminate?instanceId="+e ,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Add any other headers as needed
            }
          }
        ).then(response => {
          // Check if the request was successful
          if (!response.ok) {
            throw new Error('Could not stop execution.');
          }
        })
        .then(data => {
          if(data.statusCode==200){
            localStorage.removeItem('currenttcSequence')
          } else{
          }
          
        })
        .catch(error => {
          // Handle any errors that occurred during the fetch
          console.error('Fetch error in stop execution:', error);
        });
      }
  
      };
    const executeSequence = async (seq) => {
      localStorage.setItem('currenttcSequence',seq);
      var formdata = {
        token: "",
        ruleType: "Sequence",
        ruleGroups: [],
        ruleSubGroups: [],
        rule: "",
        sequence:seq ,
        inputData: {},
      };
    
      try {
        const response = await fetch(
          process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formdata),
          }
        );
    
        // Check if the response is OK and has content
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        // Make sure response has content before parsing
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          const data = await response.json(); // Parse only if the content is JSON
          
          if (Array.isArray(data) && data.length > 0 && data[0].resultValue != null) {
            const resultValue = data[0].resultValue;
            console.log('resultValue print', resultValue);   
            setOperation('');
          } else {
            console.error('Unexpected response structure or resultValue is null.');
          }
        } else {
          console.error('The response is not valid JSON.');
          
        }
      } catch (error) {
        console.error("Error:", error.message);
        if (typeof setOperation === 'function') {
          setOperation('');  // Ensure setOperation is defined
        }
      }
    };    
const CheckLLBInterlock = async (interlock) => {
  try {
    let url =""
    if(interlock=='llb') {
      url="/api/Interlock/CheckLLBInterlock"
    } else  if(interlock=='tc') {    
        url="/api/Interlock/CheckTCInterlock"
    }
   const response = await fetch(process.env.REACT_APP_VIA_RULE_ORCHES_API+url);
    // Check if the request was successful
    if (response.ok) {
      const result = await response.json();
      // Assuming the API returns a boolean, set the state accordingly
      console.log(' fetch data ok')
      if(result.status==true) {
        if(interlock=='llb') { 
          executeSequence("LLBOnlineSequence")
        } else {
          executeSequence("TransferChamberOnlineSeq ")
        }
        
        console.log(' fetch data success')
      }
    } else {
      console.log('Failed to fetch data')          
    }
  } catch (err) {
    console.log(err)
  }
};

    return (
      <>
      <div className="">
        {location.pathname != "/" && (
          <Header layoutdesign={props.layoutdesign} />
        )}
        <div className="px-20 bg-blue-50 flex gap-4 pt-2">
          <div className="flex-1">
            {location.pathname != "/" && (
              <TabNav tabs={props.layoutdesign.tabs} type="screens"></TabNav>
            )}
            <div className="theme-lite-bg px-3 rounded-r-lg rounded-b-lg">
              {content.map(({ module, data }, index) => (
                <div key={index}>{React.cloneElement(module, data)}</div>
              ))}
            </div>
          </div>
          <div className=" rounded-sm w-24 relative">
            {/* <E95sidemenu /> */}
            <div className="dysing-bg px-2 rounded-md w-28 h-full" style={{ display: 'flow-root' }}>
              {/* <button className="border-4 border-gray-100 dysing-input-green px-2 py-6 text-xs font-bold w-full rounded-lg">ONLINE</button>
            <div className="mt-6 text-xs font-bold text-center">Commands</div> */}
            
            <button
              type="button"
              className="diceembossed-btn py-3 mt-2 !w-full text-xs font-bold leading-5 rounded-lg !text-gray-600 flex items-center gap-3 text-left"
              onClick={() => setStartJsPopup(true)}
            >
              <svg
                class="w-6 h-6 text-gray-800 dark:text-white mr-2 mt-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 10 18"
                transform="translate(18,-4.5)"
                style={{marginLeft: -7}}
              >
                <path d="M3.414 1A2 2 0 0 0 0 2.414v11.172A2 2 0 0 0 3.414 15L9 9.414a2 2 0 0 0 0-2.828L3.414 1Z" fill='#525459' />
              </svg>
              <span>START</span>
            </button>

            <button
              type="button"
              className="diceembossed-btn py-3 !w-full mt-3 mb-3 text-xs font-bold leading-5 rounded-lg !text-gray-600 flex justify-center gap-3"
              style={{paddingTop: 19}}
              // onClick={() => WriteToChannel(0)}
            >
              <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
                transform="translate(8,-4)"
                style={{marginLeft: -18}}
              >
                <path d="M7 5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H7Z" fill='#525459' />
              </svg>
                <span>STOP</span>
            </button>

            <hr />

            <div className="relative">

                {/* Online */}
                <button
                  className=" diceembossed-btn py-3 !w-full mt-3 text-xs font-bold leading-5 rounded-lg !text-gray-600"
                  onClick={() => setOperation("online")}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Online Operation"
                  data-tooltip-place = "left"
                  data-tooltip-variant = "info"
                >
                  ONLINE
                </button>
                {operation == "online" && (
                  <div className="fixed w-full z-50 top-0 left-0 bg-black bg-opacity-15 h-full">
                    <div
                      onClick={() => setOperation("")}
                      className="w-full h-full absolute"
                    ></div>
                    <div className="w-80 bg-white shadow-xl rounded-md  mx-auto relative top-1/3 z-50  pb-2">
                      <div class="-mt-7 -mb-3 flex relative -top-3 px-3">
                        <div class="flex-1">
                          <div class="  bg-gray-100 w-28 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1">
                            Turn Online?
                          </div>
                        </div>
                        <div>
                          <button
                            class="bg-gray-100 rounded-md border-gray-400 border px-1.5 text-gray-500 hover:bg-red-500 transition-all hover:text-white"
                            onClick={() => setOperation("")}
                          >
                            ✖
                          </button>
                        </div>
                      </div>
                      <div className=" grid grid-cols-3 gap-2 m-3 mb-1">
                        <button
                          className="p-2 hover:bg-gray-500 rounded-lg shadow hover:text-white font-semibold w-full text-center border border-gray-300"
                          onClick={() => CheckLLBInterlock("tc")}
                        >
                          TC
                        </button>
                        <button className="p-2 hover:bg-gray-500 rounded-lg hover:text-white font-semibold w-full text-center  border border-gray-300">
                          LLA{" "}
                        </button>
                        <button
                          className="p-2 hover:bg-gray-500 rounded-lg hover:text-white font-semibold w-full text-center  border border-gray-300"
                          onClick={() => CheckLLBInterlock("llb")}
                        >
                          LLB{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* Pumpdown */}
              <button className="diceembossed-btn  py-3  !w-full mt-3 text-xs font-bold leading-5 rounded-lg !text-gray-600  "
              onClick={() => setOperation("pumpdown")}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="PUMP DOWN Operation"
              data-tooltip-place = "left"
              data-tooltip-variant = "info"
              >
                PUMPDOWN
              </button>
              {operation == "pumpdown" && (
                  <div className="fixed w-full z-50 top-0 left-0 bg-black bg-opacity-15 h-full">
                    <div
                      onClick={() => setOperation("")}
                      className="w-full h-full absolute"
                    ></div>
                    <div className="w-80 bg-white shadow-xl rounded-md  mx-auto relative top-1/3 z-50  pb-2">
                      <div class="-mt-7 -mb-3 flex relative -top-3 px-3">
                        <div class="flex-1">
                          <div class="  bg-gray-100 w-28 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1">
                           Pump Down?
                          </div>
                        </div>
                        <div>
                          <button
                            class="bg-gray-100 rounded-md border-gray-400 border px-1.5 text-gray-500 hover:bg-red-500 transition-all hover:text-white"
                            onClick={() => setOperation("")}
                          >
                            ✖
                          </button>
                        </div>
                      </div>
                      <div className=" grid grid-cols-3 gap-2 m-3 mb-1">
                        <button
                          className="p-2 hover:bg-gray-500 rounded-lg shadow hover:text-white font-semibold w-full text-center border border-gray-300"
                          onClick={()=> executeSequence("TransferChamberPumpdownSeq")}
                        >
                          TC
                        </button>
                        <button className="p-2 hover:bg-gray-500 rounded-lg hover:text-white font-semibold w-full text-center  border border-gray-300">
                          LLA
                        </button>
                        <button
                          className="p-2 hover:bg-gray-500 rounded-lg hover:text-white font-semibold w-full text-center  border border-gray-300"
                          // onClick={() => CheckLLBInterlock("llb")}
                        >
                          LLB
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              {/* Vent */}
              <button className="diceembossed-btn  py-3  !w-full mt-3 text-xs font-bold leading-5 rounded-lg !text-gray-600  "
              onClick={() => setOperation("vent")}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="VENT Operation"
              data-tooltip-place = "left"
              data-tooltip-variant = "info"
              >
                VENT
              </button>
              {operation == "vent" && (
                  <div className="fixed w-full z-50 top-0 left-0 bg-black bg-opacity-15 h-full">
                    <div
                      onClick={() => setOperation("")}
                      className="w-full h-full absolute"
                    ></div>
                    <div className="w-80 bg-white shadow-xl rounded-md  mx-auto relative top-1/3 z-50  pb-2">
                      <div class="-mt-7 -mb-3 flex relative -top-3 px-3">
                        <div class="flex-1">
                          <div class="  bg-gray-100 w-28 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1">
                            Vent?
                          </div>
                        </div>
                        <div>
                          <button
                            class="bg-gray-100 rounded-md border-gray-400 border px-1.5 text-gray-500 hover:bg-red-500 transition-all hover:text-white"
                            onClick={() => setOperation("")}
                          >
                            ✖
                          </button>
                        </div>
                      </div>
                      <div className=" grid grid-cols-3 gap-2 m-3 mb-1">
                        <button
                          className="p-2 hover:bg-gray-500 rounded-lg shadow hover:text-white font-semibold w-full text-center border border-gray-300"
                          onClick={()=> executeSequence("TransferChamberVentSeq")}
                        >
                          TC
                        </button>
                        <button className="p-2 hover:bg-gray-500 rounded-lg hover:text-white font-semibold w-full text-center  border border-gray-300">
                          LLA
                        </button>
                        <button
                          className="p-2 hover:bg-gray-500 rounded-lg hover:text-white font-semibold w-full text-center  border border-gray-300"
                          // onClick={() => CheckLLBInterlock("llb")}
                        >
                          LLB
                        </button>
                      </div>
                    </div>
                  </div>
                )}
    
              {/* Purge 
              <button
                className=" diceembossed-btn  py-3  !w-full mt-3 text-xs font-bold leading-5 rounded-lg !text-gray-600 "
                // onMouseEnter={() => setDescription("Yes")}
                // onMouseLeave={() => setDescription("")}
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Description for PURGE"
                data-tooltip-place = "left"
                data-tooltip-variant = "info"
              >
                PURGE
              </button>*/}
              {/* Cycle Purge */}
              <button className="diceembossed-btn  py-3 px-2 !w-full !h-16 !pt-3 mt-3 text-sm font-bold leading-5 rounded-lg !text-gray-600  "
              data-tooltip-id="my-tooltip"
              data-tooltip-content="CYCLE PURGE Operation"
              data-tooltip-place = "left"
              data-tooltip-variant = "info">
                CYCLEPURGE
              </button>
              {/* Leak Rate */}
              <button className="diceembossed-btn  py-3  !w-full mt-3 text-xs font-bold leading-5 rounded-lg !text-gray-600 "
              data-tooltip-id="my-tooltip"
              data-tooltip-content="LEAK RATE Operation"
              data-tooltip-place = "left"
              data-tooltip-variant = "info"
              >
                LEAK RATE
              </button>
              {/* Water */}
              <button
                className="diceembossed-btn  py-3  !w-full mt-3 text-sm font-bold leading-5 rounded-lg !text-gray-600  "
                aria-label="Edit in StackBlitz"
                data-tooltip-id="my-tooltip"
                data-tooltip-content="WATER PURGE Operation"
                data-tooltip-place = "left"
                data-tooltip-variant = "info"
              >
                WATERPURGE
              </button>
              {/* Abort */}
              <button className="diceembossed-btn  py-3  !w-full mt-3 text-xs font-bold leading-5 rounded-lg !text-gray-600  "
               data-tooltip-id="my-tooltip"
               data-tooltip-content="ABORT Current Operation"
               data-tooltip-place = "left"
               data-tooltip-variant = "info"
              onClick={()=>terminateSequence()}>
                ABORT
              </button>
            </div>
          <Tooltip id="my-tooltip" />
          </div>

        </div>
        {location.pathname != "/" && (
          <Footer layoutdesign={props.layoutdesign} />
        )}
      </div>

      {startJsPopup &&
      <div className='absolute w-full h-full bg-black bg-opacity-25 top-0 left-0'>
        <div className='absolute top-0 left-0 h-full w-full' 
        onClick={() => setStartJsPopup(false)}></div>
        <div className='bg-white rounded-md overflow-hidden relative top-1/3 w-1/4 mx-auto'>
          <div className='text-lg bg-gray-800 text-white px-3 py-2'>Start Job</div>
          <div className='text-base px-3 py-2'>
            <div className='grid grid-cols-3 gap-2'>
              <div className='py-1'>Job ID *</div>
              <div className='col-span-2'>
                <input type='text' className='border border-gray-400 rounded-md px-2 py-1 w-full' 
                // value={jobID} onChange={(e)=>setjobID(e.target.value)}
                />
              </div>

              {/* <div className='py-1'>Route Recipe</div>
              <div className='col-span-2'>
                <select type='text' className='border border-gray-400 rounded-md px-2 py-1 w-full' 
                value={routRecipe} onChange={(e)=>setRoutRecipe(e.target.value)}>
                  <option value="LL-PM1">LL-PM1</option>
                  <option value="LP1-LL-PM">LP1-LL-PM</option>
                </select>
              </div> */}

              <div className='py-1'>Job Sequence</div>
              <div className='col-span-2'>
                <select type='text' className='border border-gray-400 rounded-md px-2 py-1 w-full' 
                value={selectedJobSequenceId} onChange={(e) => setSelectedJobSequenceId(e.target.value)}
                >                  
                  <option>Select Sequence</option>
                    {jobSequences && jobSequences.length>0 && 
                      jobSequences.map((job, i) => 
                      <option key={job.id+i+"jb-list"} value={job.id}>{job.jobSequenceName}</option>
                    )}
                </select>
              </div>

              <div className='py-1'>No of cycles</div>
              <div className='col-span-2'>
                <input type='number' className='border border-gray-400 rounded-md px-2 py-1 w-full' 
                value={cycles} 
                onChange={(e)=>setCycles(e.target.value)}
                />
              </div>
              
            </div>
            <div className='grid grid-cols-2 gap-4 mt-3'>
              <button className='bg-gray-800 hover:bg-gray-700 text-lg p-2 rounded-md text-white font-semibold' 
                // onClick={()=>WriteToChannel(1)}
              >Start</button>
              <button 
                className='bg-gray-200 hover:bg-gray-300 text-lg  p-2 rounded-md font-semibold'  
                onClick={() => setStartJsPopup(false)}
                >
                  Cancel</button>
            </div>
           </div>
        </div>
      </div>
  }
    </>);
  }