export const combineNames = (firstName, middleName, lastName) => {
  return `${firstName} ${middleName} ${lastName}`.trim();
};

export function getCookieDomain(hostname) {
  // Check if it's localhost
  if (hostname === "localhost" || hostname === "127.0.0.1") {
    return hostname;
  }
  // For all other cases, get the root domain
  const parts = hostname.split(".");
  return "." + parts.slice(-2).join(".");
}
